
import { defineComponent } from "vue";
import Card from "@/components/molecules/dashboard/Card.vue";
import NFTViewForm from "@/components/sections/NFTViewForm.vue";
import ConfigPane from "@/components/sections/ConfigPane.vue";

export default defineComponent({
  name: "SeccessfulMintSection",
  data() {
    return {
      cards: "",
      mintToken: null,
      ownerToken: null,
      nftId: null,
      dataNames: {
        mintingResults: "minting results",
      },
      status: null,
      myNFT: null,
    };
  },
  components: {
    Card,
    NFTViewForm,
    ConfigPane,
  },
  created() {
    this.cards = this.$route.params.count;
    this.getNewStatus();
  },
  watch: {
    status: {
      deep: true,
      handler() {
        this.getNft()
      },
    },
  },
  methods: {
    async getNewStatus() {
      const web3 = require("@solana/web3.js");
      var connection = new web3.Connection(
        web3.clusterApiUrl("devnet"),
        "confirmed"
      );
      this.status = await connection.getTransaction(this.cards);
      if(this.status === null) {
        document.location.reload()
      }
      for (let i = 0; i < this.status.meta.postTokenBalances.length; i++) {
        this.mintToken = this.status.meta.postTokenBalances[i].mint;
        this.ownerToken = this.status.meta.postTokenBalances[i].owner;
      }
      this.nftId = this.$route.query.id;
      this.$store
        .dispatch("getNFTs/setAddress", {
          id: this.nftId,
          address: this.mintToken,
          owner: this.ownerToken,
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error);
          }
        );
    },
    async getNft() {
      const NFTs = require("@/plugins/solana-nft-tools");
      const web3 = require("@solana/web3.js");
      const conn = new web3.Connection(
        web3.clusterApiUrl("devnet"),
        "confirmed"
      );
      this.myNFT = await NFTs.getNFTByMintAddress(conn, this.mintToken);
    },
  },
});
