import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16cde016"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "seccessful-mint-section" }
const _hoisted_2 = { class: "seccessful-mint-section__title" }
const _hoisted_3 = {
  key: 0,
  class: "preloader"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(this.dataNames.mintingResults), 1),
    (this.myNFT == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    (this.myNFT)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Card, {
            name: this.myNFT.name,
            imgCatalog: this.myNFT.image,
            attributes: this.myNFT.attributes
          }, null, 8, ["name", "imgCatalog", "attributes"])
        ]))
      : _createCommentVNode("", true)
  ]))
}