
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { INFTParams } from "@/common/helpers/types";
import useWallet from "@/composables/wallet";
import useError from "@/composables/error";

export default defineComponent({
  props: {
    isLoading: Boolean,
  },
  emits: ["submit-form"],
  setup(props, ctx) {
    const { error, clearError, tryConvertToPk } = useError();
    const owner = ref<string | null>(null);
    const chosenMethod = ref<string>("address")
    const byAddress = computed(() => chosenMethod.value === "address");
    const byWallet = computed(() => chosenMethod.value === "wallet");
    const { wallet, getWalletAddress } = useWallet();
    watch(byWallet, () => {
      if (getWalletAddress()) owner.value = getWalletAddress()!.toBase58();
    });
    watch(wallet, () => {
      if (getWalletAddress()) owner.value = getWalletAddress()!.toBase58();
    });
    const missingWallet = computed(() => getWalletAddress() === null);
    const prepareParams = (): INFTParams | null => {
      clearError();
      const params = {
        owner:
          byAddress.value || byWallet.value
            ? tryConvertToPk(localStorage.getItem("phantom-token"))
            : undefined,
      } as INFTParams;
      if (error.value) {
        return null;
      }
      return params;
    };

    const emitSubmitForm = () => {
      const params = prepareParams();
      if (params) ctx.emit("submit-form", params);
    };

    onMounted(() => {
      getWalletAddress();
      prepareParams();
      emitSubmitForm();
      const route = useRoute();
      const {
        address: goAddress,
      } = route.params;
      if (goAddress) {
        chosenMethod.value = "address";
        owner.value = goAddress as any as string;
        emitSubmitForm();
      }
    });

    return {
      error,
      owner,
      chosenMethod,
      byAddress,
      byWallet,
      missingWallet,
      emitSubmitForm,
    };
  },
});
