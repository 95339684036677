
import { defineComponent } from 'vue'
import SecondLayout from '@/templates/SecondLayout.vue'
import SeccessfulMintSection from '@/components/sections/dashboard/SeccessfulMintSection.vue'

export default defineComponent({
  name: 'SeccessfulMint',
  components: {
    SecondLayout,
    SeccessfulMintSection
  },
  mounted() {
    const token = localStorage.getItem('phantom-token');
    if(token == null) {
      this.$router.push('/registration-phantom')
    }
  }
})
