
import { computed, defineComponent, onMounted } from 'vue';
import { WalletName } from '@/plugins/wallet-adapter-wallets';
import useCluster, { Cluster } from '@/composables/cluster';
import useWallet from '@/composables/wallet';

export default defineComponent({
  setup() {
    const { cluster, setCluster, getClusterURL } = useCluster();
    // cluster
    const chosenCluster = computed({
      get() {
        return Cluster.Devnet;
      },
      set(newVal: Cluster.Devnet) {
        setCluster(newVal);
      },
    });

    // wallet
    const { getWalletName, setWallet } = useWallet();
    const chosenWallet = computed({
      get() {
        return getWalletName();
      },
      set(newVal: WalletName.Phantom | null) {
        setWallet(newVal, getClusterURL());
      },
    });

    onMounted(() => {
      const { setWallet } = useWallet();
      setCluster(Cluster.Devnet);
      setWallet('Phantom', getClusterURL());
    });
    

    return {
      Cluster,
      chosenCluster,
      WalletName,
      chosenWallet,
    };
  },
});
